;(function (window) {
  // Handle notifying Bloomreach of page views
  if (!window.brxmNotifyUrl) {
    return
  }

  const notifyUrl = window.brxmNotifyUrl
  const getNotifyUrl = url => {
    if (url.startsWith('/')) {
      url = window.location.origin + url
    }
    if (url.indexOf('?') !== -1) {
      url = url.substring(0, url.indexOf('?'))
    }
    if (url.indexOf('#') !== -1) {
      url = url.substring(0, url.indexOf('#'))
    }
    if (url.endsWith('/')) {
      url = url.substring(0, url.length - 1)
    }
    return notifyUrl + '?url=' + url
  }

  if (window.fetch) {
    fetch(getNotifyUrl(window.location.href))
  }

  // We're using sendBeacon; if it doesn't exist user is on an old browser and we just skip this
  if (!navigator.sendBeacon) {
    return
  }

  window.addEventListener('click', e => {
    if (
      e === undefined ||
      (e.path === undefined && e.composedPath() === undefined)
    ) {
      return
    }

    const elements = e.path || e.composedPath()
    for (let element of elements) {
      if (!element.hasAttribute('href')) {
        return
      }

      const href = element.getAttribute('href')
      const location = getNotifyUrl(window.location.href)
      const notification = getNotifyUrl(href)
      if (notification === '' || notification === location) {
        return
      }

      navigator.sendBeacon(notification)
    }
  })
})(window)
